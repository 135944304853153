<template lang="pug">
  div.row.pt-1.pb-1.px-1
    .header.row
      v-col.border-col-rigth.pt-3.pb-0(cols="3")
        translate Plate
      v-col.border-col-rigth.pt-3.pb-0(cols="2")
        translate Brand
      v-col.border-col-rigth.pt-3.pb-0(cols="2")
        translate Province
      v-col.border-col-rigth.pt-3.pb-0
        translate Aggregator
      v-col.border-col-rigth.pt-3.pb-0.text-center
        translate Price yesterday
      v-col.border-col-rigth.pt-3.pb-0.text-center
        translate Price today
      v-col.border-col-rigth.pt-3.pb-0.text-center
        translate Variation
      v-col.pt-3.pb-0.text-center
        translate Deviation typical
    .content-row.row.border-b-gray(v-if="data" v-for="(row, i) in data"  :class="{'bg-ligth-gray': i % 2 != 0}")
      v-col.pt-5(v-for="(item, index) in row" v-if="index > 3" :class="{'col-3':index === 4,'col-2':index === 5 || index === 6, 'text-center': index >= 8, 'border-col-rigth': index < 11}")
        span.terciary--text.font-weight-med(v-if="index < 5") {{item}}
        span(v-if="index >= 5 && index < 8") {{item | formatNumber(2,2)}}
        span(v-if="index >= 8 && index < 10") {{item | formatNumber(2,2)}} €
        span(v-if="index == 10" :class="{'color-red': item < 0,'color-green': item > 0 }") {{item | formatNumber(2,2)}} %
        span(v-if="index == 11") {{item | formatNumber(2,2)}} €
</template>
<script>

export default {
  props: ["data"],
  data() {
    return {
    }
  },
  created(){
  }
}
</script>
<style lang="scss" scoped>
  .header{
    width: 100%;
    border-bottom: 1px solid #4A4A4A;
    span{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .content-row{
    width: 100%;
    font-size: 14px;
  }
</style>